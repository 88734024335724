<template>
  <div id="subscription">
    <template v-if="$apollo.loading">
      <loading />
    </template>
    <template v-else>
      <b-table :data="billings" hoverable>
        <b-table-column field="#" label="#" width="40" numeric v-slot="props">{{
          billings.indexOf(props.row) + 1
        }}</b-table-column>

        <b-table-column label="Type" v-slot="props">{{
          props.row.subscriptionType
        }}</b-table-column>

        <b-table-column label="Status" v-slot="props">{{
          props.row.status
        }}</b-table-column>

        <b-table-column label="Month factor" v-slot="props">{{
          props.row.monthFactor
        }}</b-table-column>

        <b-table-column label="Activation Date" v-slot="props">{{
          new Date(props.row.subscriptionDate).toDateString()
        }}</b-table-column>

        <b-table-column label="Expiry Date" v-slot="props">{{
          new Date(props.row.expirationDate).toDateString()
        }}</b-table-column>

        <b-table-column label="Actions" width="160" v-slot="props">
          <b-button
            icon-left="dollar-sign"
            class="is-success is-small"
            style="height: 25px"
            @click="$router.push(`/school/${schoolId}/subscription_payment`)"
            v-if="props.row.status === 'Pending'"
            >Pay</b-button
          >
          <action-buttons
            @initialize-info="initializeInfo(props.row)"
            position="is-left"
            :show-update="false"
            :show-remove="false"
            v-else
          />
        </b-table-column>
      </b-table>
    </template>
  </div>
</template>

<script>
import gql from 'graphql-tag'
import BILLINGS from '../../../graphql/billing/Billings.gql'
import { fetchBilling } from '@/assets/js/app_info.js'

export default {
  name: 'subscription',
  data() {
    return {
      schoolId: null,
      pageTitle: 'Subscriptions',
      billings: [],
      isExpired: false,
    }
  },
  methods: {
    initializeInfo(billing) {
      this.$router.push(
        `/school/${this.schoolId}/subscription_info/${billing.id}`
      )
    },
  },
  mounted() {
    this.schoolId = parseInt(this.$route.params.school_id)
    this.$store.commit('setPageTitle', this.pageTitle)
    fetchBilling().then((billing) => {
      this.isExpired = billing.expired
      const subMenus = []
      if (this.isExpired) {
        subMenus.push({
          name: 'Subscription Renewal',
          route: `/school/${this.schoolId}/subscription_renewal`,
        })
      }
      this.$store.commit('setSubMenus', subMenus)
    })

    this.$apollo.addSmartQuery('billings', {
      query: BILLINGS,
      variables: {
        schoolId: this.schoolId,
      },
    })

    this.$apollo.queries.billings.refetch()
  },
}
</script>
